import { TableCell } from "@mui/material";
import { FC } from "react";
import IUserTableCell from "./IUserTableCell";

const UsersTableCell: FC<IUserTableCell> = ({ text }) => {
  return (
    <TableCell
      sx={{
        maxWidth: 150,
        whiteSpace: "wrap",
      }}>
      {text}
    </TableCell>
  );
};

export default UsersTableCell;
