import { FC, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import IFormSelect from "./IFormSelect";
import CustomLabel from "../CustomLabel/CustomLabel";

const FormSelect: FC<IFormSelect> = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    required = false,
    options,
    placeholder,
  } = props;
  const [error, setError] = useState<string | null>(null);

  const handleBlur = () => {
    if (required && !value) {
      setError("Поле обязательно для заполнения");
    } else {
      setError(null);
    }
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      required={required}
      error={!!error}
      sx={{ position: "relative" }}>
      {label && <CustomLabel label={label} required={required} error={error} />}
      <Select
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
        displayEmpty
        renderValue={(selected) => {
          if (selected === "") {
            return (
              <Typography
                sx={{ color: "rgba(187, 187, 187, 1)", fontSize: 13 }}>
                {placeholder || "Выберите..."}
              </Typography>
            );
          }

          const selectedOption = options.find(
            (option) => option.value === selected
          );

          return selectedOption ? selectedOption.label : selected;
        }}>
        {!required && <MenuItem value={""}>{"-"}</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;
