import {
  Avatar,
  Box,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ITestUser } from "../../entities/TestUser/models/ITestUser";
import testUsersStore from "../../store/testUsers.store";
import LabeledText from "./ui/LabeledText/LabeledText";

const CurrentUserPage: FC = observer(() => {
  const { id } = useParams();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    testUsersStore.setCurrentUserField(name as keyof ITestUser, value);
  };

  const initials = `${testUsersStore.currentUser?.firstName[0].toUpperCase()}${
    testUsersStore.currentUser?.lastName
      ? testUsersStore.currentUser?.lastName[0].toUpperCase()
      : ""
  }`;

  useEffect(() => {
    const userId = Number(id);
    testUsersStore.getUserById(userId);

    return () => {
      testUsersStore.setCurrentUser(null);
    };
  }, [id]);

  return (
    <Box
      sx={{ p: {xs: 0, lg: 2, md: 2, sm: 2}, display: "flex", justifyContent: "center", height: "100%" }}>
      <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
        {testUsersStore.currentUser?.id === 0 ? (
          <Typography variant="h4">Пользователь не найден</Typography>
        ) : testUsersStore.isLoading ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Skeleton variant="circular" width={300} height={300} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={200} height={40} />
              <Skeleton variant="text" width={300} height={30} />
              <Skeleton variant="text" width={250} height={30} />
              <Skeleton variant="text" width={150} height={30} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {testUsersStore.currentUser ? (
                <Avatar
                  sx={{
                    width: { md: 300, sm: 200, xs: 200 },
                    height: { md: 300, sm: 200, xs: 200 },
                    fontSize: 100,
                  }}
                  src={testUsersStore.currentUser.imageUrl || undefined}>
                  {!testUsersStore.currentUser.imageUrl && initials}
                </Avatar>
              ) : (
                <Skeleton variant="circular" width={300} height={300} />
              )}
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex" }}>
                <TextField
                  name="firstName"
                  variant="standard"
                  value={testUsersStore.currentUser?.firstName}
                  onChange={handleChange}
                  margin="normal"
                />
                <TextField
                  name="lastName"
                  variant="standard"
                  value={testUsersStore.currentUser?.lastName}
                  onChange={handleChange}
                  margin="normal"
                />
              </Box>
              <LabeledText
                title="Email"
                value={testUsersStore.currentUser?.email}
              />
              <LabeledText
                title="Компания"
                value={testUsersStore.currentUser?.company}
              />
              <LabeledText
                title="Роль"
                value={testUsersStore.currentUser?.roleName}
              />
              <LabeledText
                title="Дата создания"
                value={testUsersStore.currentUser?.createdAt}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
});

export default CurrentUserPage;
