import { AxiosResponse } from "axios";
import {
  ITestUser,
  ITestUserRequestData,
  ITestUserResponseData,
} from "../../entities/TestUser/models/ITestUser";
import $testUsers_api from "../../shared/api/testUsersAxios";

export default class TestUsersService {
  static getTestUsers(): Promise<AxiosResponse<ITestUser[]>> {
    return $testUsers_api.get("/users/?company=caseguru");
  }

  static createTestUser(
    data: ITestUserRequestData
  ): Promise<AxiosResponse<ITestUserResponseData>> {
    return $testUsers_api.post("/users/", data);
  }

  static getTestUserById(
    id: number
  ): Promise<AxiosResponse<ITestUser>> {
    return $testUsers_api.get(`/users/${id}`);
  }
}
