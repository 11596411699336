export enum ALL_ROLES {
  HEAD = 'head',
  ADMIN = 'admin',
  PRE_HEAD_MANAGER = "pre-head/manager",
  SEARCH_HEAD_MANAGER = 'search-head/manager',
  FINANCE_MANAGER = "finance-manager",
  RELEASE_MANAGER = 'release_manager',
  SEARCH_MANAGER = 'search_manager',
  trainee = 'trainee/manager',
  CONTENT_MANAGER = 'content-manager'
}

export enum ALL_RULES {
  ADMIN = 'admin',

  get_integrations = "get_integrations",
  edit_self_integrations = "edit_self_integrations",
  edit_other_integrations = "edit_other_integrations",
  create_integration = "create_integration",

  get_channels = "get_channels",
  edit_channels = "edit_channels",
  create_channel = 'create_channel',
  edit_foundById = 'edit_foundById',
  edit_releaseById = 'edit_releaseById',

  edit_payment='edit_payment',

  read_coefs = 'read_coefs',
  edit_plan = 'edit_plan',
  concord = 'concord',

  concord_ = 'concord_',
  concord_1 = 'concord_1',
  concord_2 = 'concord_2',
  concord_3 = 'concord_3',

  release = "release",
  search = "search",

  search_head = 'search-head',
  release_head = 'release-head',

  lk_my_motivation = 'lk_my_motivation',
  lk_finances = "lk_finances",

  block_channel = 'block_channel',

  head = 'head',

  delete_integrations = 'delete_integrations',
  edit_date_given = 'edit_date_given',

  VIEW_TZ_PAGE = 'view_tz_page',
  VIEW_ANALYTICS_PAGE = 'view_analytics_page',
  VIEW_COUNTERAGENT_PAGE = 'view_counteragent_page',
  VIEW_MOTIVATION_PAGE = 'view_motivation_page',
  
  view_payments_stats = 'view_payments_stats',
  edit_allowUsage_tz = 'edit_allowUsage_tz',

  view_channel_transfer_page='view_channel_transfer_page',
  edit_channel_transfer='edit_channel_transfer',

  delete_channel = 'delete_channel',

  view_users_page = 'view_users_page',
  edit_users_page = 'edit_users',
}