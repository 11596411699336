import { Box, Skeleton, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ModalForm from "../../components/ModalForm/ModalForm";
import UsersPageHeader from "../../components/UsersPageHeader/UsersPageHeader";
import UsersTable from "../../components/UsersTable/UsersTable";
import testUsersStore from "../../store/testUsers.store";
import { observer } from "mobx-react-lite";

const UsersPage: FC = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalVisibility = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (!testUsersStore.isDataLoaded) {
      testUsersStore.getAllUsers();
      testUsersStore.logUsers();
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        p: 2,
        height: "100vh",
        flexDirection: "column",
        rowGap: 2,
      }}>
      <UsersPageHeader onClick={toggleModalVisibility} />
      <ModalForm open={isModalOpen} onClose={toggleModalVisibility} />
      {testUsersStore.isLoading ? (
    <Skeleton variant="rectangular" height="100%" />
  ) : testUsersStore.error ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="h6" color="error">
        Произошла ошибка: {testUsersStore.error}
      </Typography>
    </Box>
  ) : (
    <UsersTable />
  )}
    </Box>
  );
});

export default UsersPage;
