import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { NO_DATA } from "../../shared/constants/noData";
import testUsersStore from "../../store/testUsers.store";
import UsersTableCell from "./ui/UsersTableCell";

const UserTable = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleRowClick = (id: number) => {
    navigate(location.pathname + "/" + id);
  };

  return (
    <Paper
      sx={{
        position: "relative",
        height: "100%",
        overflow: "auto",
        maxWidth: "calc(100vw - (70px + (16px * 2)))",
      }}>
      <TableContainer sx={{ overflowX: "auto", maxHeight: "100%" }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <UsersTableCell text="ID" />
              <UsersTableCell text="Имя" />
              <UsersTableCell text="Фамилия" />
              <UsersTableCell text="Email" />
              <UsersTableCell text="Компания" />
              <UsersTableCell text="Роль" />
              <UsersTableCell text="Дата создания" />
            </TableRow>
          </TableHead>
          <TableBody>
            {testUsersStore.users.map((user) => (
              <TableRow
                key={user.id}
                hover
                style={{ cursor: "pointer" }}
                onClick={() => handleRowClick(user.id)}>
                <UsersTableCell text={user.id || NO_DATA}></UsersTableCell>
                <UsersTableCell
                  text={user.firstName || NO_DATA}></UsersTableCell>
                <UsersTableCell
                  text={user.lastName || NO_DATA}></UsersTableCell>
                <UsersTableCell text={user.email || NO_DATA}></UsersTableCell>
                <UsersTableCell text={user.company || NO_DATA}></UsersTableCell>
                <UsersTableCell
                  text={user.roleName || NO_DATA}></UsersTableCell>
                <UsersTableCell
                  text={user.createdAt || NO_DATA}></UsersTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

export default UserTable;
