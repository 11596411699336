import { makeAutoObservable } from "mobx";
import {
  ITestUser,
  ITestUserFormData,
  ITestUserRequestData,
  ITestUserResponseData,
} from "../entities/TestUser/models/ITestUser";
import TestUsersService from "../services/testUsers/testUsers.service";

class TestUsersStore {
  currentUser: ITestUser | null = null;
  users: ITestUser[] = [];
  isLoading = false;
  isDataLoaded = false;
  error = "";
  confirmPassword = "";
  passwordMismatch = false;
  form: ITestUserFormData = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    roleCode: "",
    createdAt: null,
    company: "",
  };
  constructor() {
    makeAutoObservable(this);
  }

  setField<K extends keyof ITestUserFormData>(
    name: K,
    value: ITestUserFormData[K]
  ) {
    this.form[name] = value;
  }

  setCurrentUserField<K extends keyof ITestUser>(
    field: K,
    value: ITestUser[K]
  ) {
    if (this.currentUser) {
      this.currentUser[field] = value;

      const userIndex = this.users.findIndex(
        (user) => user.id === this.currentUser?.id
      );
      if (userIndex !== -1) {
        this.users[userIndex][field] = value;
      }
    }
  }

  setConfirmPassword(value: string) {
    this.confirmPassword = value;
  }

  setUsers(users: ITestUser[]) {
    this.users = users;
  }

  setCurrentUser(user: ITestUser | null) {
    this.currentUser = user;
  }

  addUser(user: ITestUserResponseData) {
    const newUser = { ...user, roleName: user.roleCode };

    this.users.unshift(newUser);
  }

  createUser() {
    this.setLoading(true);
    this.error = "";
    const userData = {
      ...this.form,
      createdAt: this.form.createdAt ? this.form.createdAt.toISOString() : null,
    };

    return TestUsersService.createTestUser(userData as ITestUserRequestData)
      .then((res) => {
        this.addUser(res.data);
        this.resetForm();
        return res;
      })
      .catch((err) => {
        this.error = err.message;
        throw err;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  getAllUsers() {
    this.setLoading(true);
    this.error = "";
    return TestUsersService.getTestUsers()
      .then((res) => {
        this.setUsers(res.data);
        this.setIsDataLoaded(true);
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  getUserById(id: number) {
    this.setLoading(true);
    this.error = "";
    TestUsersService.getTestUserById(id)
      .then((res) => {
        this.setCurrentUser(res.data);
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsDataLoaded(isDataLoaded: boolean) {
    this.isDataLoaded = isDataLoaded;
  }

  logUsers() {
    console.log(this.users);
  }

  logIsLoading() {
    console.log(this.isLoading);
  }

  resetForm() {
    this.form = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      roleCode: "",
      createdAt: null,
      company: "",
    };
    this.confirmPassword = "";
  }
}

export default new TestUsersStore();
