import { ALL_ROLES } from "../rules/interface/EnumRights";

enum ALL_COMPANIES {
  GOOGLE = "google",
  APPLE = "apple",
}

export const rolesOptions = [
  {
    label: "Администратор",
    value: ALL_ROLES.ADMIN,
  },
  {
    label: "Поисковый менеджер",
    value: ALL_ROLES.SEARCH_MANAGER,
  },
];

export const companysOptions = [
  {
    label: "Google",
    value: ALL_COMPANIES.GOOGLE,
  },
  {
    label: "Apple",
    value: ALL_COMPANIES.APPLE,
  },
];
