import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import DatePickerComponent from "./ui/DatePicker/DatePicker";
import FormInput from "./ui/FormInput/FormInput";
import FormSelect from "./ui/FormSelect/FormSelect";
import { IModalForm } from "./IModalForm";
import {
  companysOptions,
  rolesOptions,
} from "../../shared/constants/selectorOptions";
import testUsersStore from "../../store/testUsers.store";
import { observer } from "mobx-react-lite";
import { ITestUserFormData } from "../../entities/TestUser/models/ITestUser";
import { emailRegex } from "./ui/FormInput/shared/regex";

const ModalForm: FC<IModalForm> = observer((props) => {
  const { open, onClose } = props;
  const [passwordMismatchError, setPasswordMismatchError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { value, name } = e.target;

    if (name === "confirmPassword") {
      const passwordError =
        value !== testUsersStore.form.password ? "Пароли не совпадают" : "";

      setPasswordMismatchError(passwordError);
      testUsersStore.setConfirmPassword(value);
    } else {
      testUsersStore.setField(name as keyof ITestUserFormData, value);
    }

    validateForm();
  };

  const handleChangeDate = (date: Dayjs | null) => {
    if (date) {
      testUsersStore.setField("createdAt", date);
    }

    validateForm();
  };

  const validateForm = () => {
    const isFormValid =
      testUsersStore.form.firstName.trim() !== "" &&
      testUsersStore.form.email.trim() !== "" &&
      emailRegex.test(testUsersStore.form.email) &&
      testUsersStore.form.password.trim() !== "" &&
      testUsersStore.confirmPassword.trim() !== "" &&
      passwordMismatchError === "" &&
      testUsersStore.form.password === testUsersStore.confirmPassword &&
      testUsersStore.form.createdAt !== null &&
      testUsersStore.form.roleCode.trim() !== "";

    setIsFormValid(isFormValid);
  };

  const validateDate = (date: Dayjs | null) => {
    if (date && date.isAfter(dayjs())) {
      return "Дата не может быть больше текущей";
    }

    return null;
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    testUsersStore
      .createUser()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    setIsFormValid(false);
    setError("");
  }, [open]);

  return (
    <Modal
      onClose={onClose}
      open={open}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Paper elevation={2} sx={{ p: 4, position: "relative" }}>
        <Close
          sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
          onClick={onClose}
        />
        <Typography variant="h5" pb={2} align="center">
          Создание пользователя
        </Typography>
        <FormControl
          onSubmit={submitForm}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            minWidth: 300,
          }}>
          <FormInput
            name="firstName"
            type="text"
            label="Имя"
            value={testUsersStore.form.firstName}
            placeholder="Введите имя"
            required
            onChange={handleChange}
          />
          <FormInput
            name="lastName"
            type="text"
            label="Фамилия"
            value={testUsersStore.form.lastName || ""}
            placeholder="Введите фамилию"
            onChange={handleChange}
          />
          <FormInput
            name="email"
            type="email"
            label="Email"
            value={testUsersStore.form.email}
            placeholder="Введите email"
            required
            isEmail
            onChange={handleChange}
          />
          <FormInput
            name="password"
            type="password"
            label="Пароль"
            value={testUsersStore.form.password}
            placeholder="Введите пароль"
            required
            isPassword
            onChange={handleChange}
          />
          <FormInput
            name="confirmPassword"
            customError={passwordMismatchError}
            type="password"
            label="Пароль"
            value={testUsersStore.confirmPassword}
            placeholder="Повторите пароль"
            required
            isPassword
            onChange={handleChange}
          />
          <DatePickerComponent
            value={testUsersStore.form.createdAt}
            onChange={handleChangeDate}
            placeholder="дд.мм.год"
            label="Дата создания"
            required
            validate={validateDate}
          />
          <FormSelect
            name="company"
            value={testUsersStore.form.company}
            onChange={handleChange}
            label="Компания"
            options={companysOptions}
            placeholder="Выберите компанию"
          />
          <FormSelect
            name="roleCode"
            value={testUsersStore.form.roleCode}
            onChange={handleChange}
            label="Роль"
            options={rolesOptions}
            placeholder="Выберите роль"
            required
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid || testUsersStore.isLoading}
            startIcon={
              testUsersStore.isLoading ? <CircularProgress size={20} /> : null
            }>
            {testUsersStore.isLoading ? "" : "Сохранить"}
          </Button>
        </FormControl>
        {error && <Typography color="error">{error}</Typography>}
      </Paper>
    </Modal>
  );
});

export default ModalForm;
