import { AddCircleOutline } from "@mui/icons-material";
import { Button, Paper, Typography } from "@mui/material";
import IUsersHeader from "./IUsersPageHeader";
import { FC } from "react";
import { observer } from "mobx-react-lite";
import testUsersStore from "../../store/testUsers.store";

const UsersPageHeader: FC<IUsersHeader> = observer(({ onClick }) => {
  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        rowGap: {xs: 4, md: 0},
      }}
      elevation={2}>
      <Typography variant="h5">Пользователи</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={onClick}
        disabled={testUsersStore.isLoading}>
        Создать пользователя
      </Button>
    </Paper>
  );
});

export default UsersPageHeader;
