import { Typography } from "@mui/material";
import { FC } from "react";
import ICustomLabel from "./ICustomLabel";

const CustomLabel: FC<ICustomLabel> = ({ label, required, error }) => {
  return (
    <Typography
      component="label"
      sx={{
        position: "absolute",
        left: 10,
        top: -9,
        fontSize: "0.6rem",
        pointerEvents: "none",
        color: error ? "#d32f2f" : "rgba(0, 0, 0, 0.6)",
        backgroundColor: "white",
        padding: "0 4px",
        zIndex: 1,
      }}>
      {label}
      {required ? (
        <Typography component="span" color={"red"} fontSize={"0.6rem"}>
          *
        </Typography>
      ) : (
        ""
      )}
    </Typography>
  );
};

export default CustomLabel;
