import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FC, useState } from "react";
import { IDatePicker } from "./IDatePicker";
import { Dayjs } from "dayjs";
import CustomLabel from "../CustomLabel/CustomLabel";

const DatePickerComponent: FC<IDatePicker> = (props) => {
  const { value, onChange, placeholder, label, required, validate, ...rest } =
    props;

  const [error, setError] = useState<null | string>(null);

  const validateRequired = (value: Dayjs | null) => {
    if (required && !value) {
      return "Поле обязательно для заполнения";
    }

    return null;
  };

  const handleChange = (newValue: Dayjs | null) => {
    let validationError = validateRequired(newValue);

    if (!validationError && validate) {
      validationError = validate(newValue);
    }

    setError(validationError);

    if (!validationError) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
        <DatePicker
          value={value}
          onChange={handleChange}
          sx={{ width: "100%" }}
          slotProps={{
            textField: {
              variant: "outlined",
              placeholder: placeholder,
              size: "small",
              required: required,
              error: !!error,
              helperText: error,
              ...rest,
            },
          }}
        />
        {label && <CustomLabel label={label} required={required} error={error} />}
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerComponent;
