import { Typography } from "@mui/material";
import { FC } from "react";
import ILabeledText from "./ILabeledText";
import { NO_DATA } from "../../../../shared/constants/noData";

const LabeledText: FC<ILabeledText> = (props) => {
  const { title, value } = props;
  return (
    <Typography color="primary" sx={{fontSize: {xs: 14, sm: 16, md: 18, lg: 20}}}>
      {title}
      {":"}
      <Typography color="textSecondary" component={"span"} sx={{ ml: 1 }}>
        {value || NO_DATA}
      </Typography>
    </Typography>
  );
};

export default LabeledText;
