import axios from "axios";

const API_URL = "https://sso.caseguru.ru";

const $testUsers_api = axios.create({
  withCredentials: false,
  baseURL: API_URL,
});

export default $testUsers_api;
