import { AccountCircle, Email, Key } from "@mui/icons-material";
import { Checkbox, InputAdornment, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import IFormInput from "./IFormInput";
import { emailRegex } from "./shared/regex";

const FormInput: FC<IFormInput> = (props) => {
  const {
    name,
    type,
    label,
    value,
    placeholder,
    required,
    onChange,
    isEmail,
    isPassword,
    withIcon = true,
    customError,
    ...rest
  } = props;

  const [error, setError] = useState<null | string>(null);
  const [touched, setTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email: string) => {
    if (!emailRegex.test(email)) {
      setError("Некорректный email");
    } else {
      setError(null);
    }
  };

  const validateRequired = (value: string) => {
    if (required && !value) {
      setError("Поле обязательно для заполнения");
    } else {
      setError(null);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (isEmail && touched) {
      validateEmail(value);
    } else if (required) {
      validateRequired(value);
    }

    onChange(e);
  };

  const handleBlur = () => {
    if (required && !value) {
      validateRequired(value);
    } else if (isEmail) {
      validateEmail(value);
    }

    setTouched(true);
  };

  return (
    <TextField
      name={name}
      label={
        <Typography component="span">
          {label}
          <Typography component={"span"} color="red">
            {required ? "*" : ""}
          </Typography>
        </Typography>
      }
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      value={value}
      variant="outlined"
      color="primary"
      placeholder={placeholder}
      onChange={handleChange}
      error={!!customError || !!error}
      helperText={customError || error}
      onBlur={handleBlur}
      autoComplete={isPassword ? "on" : "off"}
      InputProps={{
        startAdornment: withIcon && (
          <InputAdornment position="start">
            {isEmail ? <Email /> : isPassword ? <Key /> : <AccountCircle />}
          </InputAdornment>
        ),
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <Checkbox
              checked={showPassword}
              onChange={(event) => setShowPassword(event.target.checked)}
            />
            <Typography
              component={"span"}
              sx={{ cursor: "pointer", fontSize: "12px" }}>
              Показать пароль
            </Typography>
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiFormHelperText-root": {
          position: "absolute",
          bottom: 0,
          left: 0,
          transform: "translateY(100%)",
          transition: "opacity 0.3s ease-in-out",
        },
      }}
      size="small"
      {...rest}
    />
  );
};

export default FormInput;
